/*
The wrapper class limits the maximum inline size (width in horizontal-tb 
writing mode) to a specified maximum size.
*/
.wrapper {
  --wrapper-inline-size: var(--max-inline-size, 1200px);
  inline-size: 100%;
  margin-inline: auto;
  max-inline-size: var(--wrapper-inline-size);
}

.wrapper.measure {
  --wrapper-inline-size: var(--measure, 65ch);
}
