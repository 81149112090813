.inline-size-full {
  inline-size: 100% !important;
}

.margin-0 {
  margin: 0 !important;
}

.margin-block-end-1 {
  margin-block-end: var(--space-2xs-xs, 1rem) !important;
}

.margin-block-start-1 {
  margin-block-start: var(--space-2xs-xs, 1rem) !important;
}

.max-inline-size-full {
  max-inline-size: 100% !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding--1 {
  padding: var(--space-3xs, 0.3rem) !important;
}

.padding-block--1 {
  padding-block: var(--space-3xs, 0.3rem) !important;
}

.padding-block-1 {
  padding-block: var(--space-2xs-xs, 1rem) !important;
}

.padding-block-2 {
  padding-block: var(--space-s-m, 1.5rem) !important;
}

.padding-inline-fluid {
  padding-inline: 5vw !important;
}
