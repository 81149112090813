/* Include the 'language-' attribute to overwrite PrismJS's style too. */
pre,
pre[class*='language-'] {
  max-inline-size: 100%;
  padding: 0;
  position: relative;
}

/* Add a (non-selectable) text above the code indicating the used language. */
/* TODO find more accessible way (selectable text) */
pre[data-language]:before {
  --border-width: 2px;
  border-block-end: var(--border-width) solid var(--color-accent);
  content: attr(data-language);
  display: block;
  max-inline-size: max-content;
  padding: 1em;
  position: relative;
  text-transform: uppercase;
  z-index: var(--z1);
}

/* Restore margin-block-start within a stack context. */
.stack pre[class*='language-'] {
  margin-block-start: var(--stack-space);
}

/* Make the + and - characters unselectable for copy/paste. */
.token.prefix.unchanged,
.token.prefix.inserted,
.token.prefix.deleted {
  user-select: none;
}

/* Full-width background color. */
.token.inserted:not(.prefix),
.token.deleted:not(.prefix) {
  background-color: #284235;
  color: inherit;
  display: block;
}
.token.deleted:not(.prefix) {
  background-color: #422828;
}

code {
  color: var(--color-accent);
}

/* 
Use padding on the inner `code` element to prevent the text content 
from squishing against the border of the pre element when the overflow 
is active (aka a scrollbar is visible). 
*/
pre > code[class*='language-'] {
  border-block-start: var(--border-width, 2px) solid #4c4c4c;
  display: inline-block;
  margin-block-start: calc(-1 * var(--border-width, 2px));
  min-inline-size: 100%;
  padding: 1em;
  white-space: pre-wrap;
  word-break: break-word;
}

/* 
TODO Add line numbers via CSS and eleventy-syntax-highlighting's 
`alwaysWrapLineHighlights` option.
Credits: https://github.com/11ty/eleventy-plugin-syntaxhighlight/issues/10
*/
/* pre {
  counter-reset: lineNumber;
}

code.language-php .token.delimiter.important:before,  /* <?php and ?> tokens *
code.language-python .token.triple-quoted-string.string:before, /* Python doc strings *
code .highlight-line:before {
  --number-spacing: 1em;
  background: #2d2d2d;
  border-inline-end: 1px solid var(--color-code-line-number, currentColor);
  color: var(--color-code-line-number, inherit);
  content: counter(lineNumber);
  counter-increment: lineNumber;
  display: inline-block;
  font-variant-numeric: tabular-nums;
  font-weight: 400;
  inline-size: calc(3 * var(--number-spacing));
  inset-inline-start: 0;
  margin-inline-end: var(--number-spacing);
  padding-inline-end: var(--number-spacing);
  padding-inline-start: 1em;
  position: sticky;
  text-align: end;
  user-select: none;
} */
