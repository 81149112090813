.breadcrumbs li:not(:last-of-type):after {
  border-block: 0.4em solid transparent;
  border-inline-start: 0.4em solid var(--color-body-text);
  content: '';
  display: inline-block;
  height: 0;
  margin-left: var(--cluster-gap, 0.5em);
  vertical-align: middle;
  width: 0;
}
