pre .clipboard-button {
  background: #4c4c4c;
  border: 0;
  color: #ccc;
  cursor: pointer;
  display: block;
  padding: 0.15em 0.5em;
  position: absolute;
  right: 1em;
  top: calc(1em - 0.15em);
  z-index: var(--z1);
}
