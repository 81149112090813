:root {
  --ratio: 1.5;
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));

  /* Utopia Type Scale */
  /* @link https://utopia.fyi/type/calculator?c=320,18,1.2,1200,22,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */
  --step--2: clamp(0.78rem, calc(0.75rem + 0.18vw), 0.88rem);
  --step--1: clamp(0.94rem, calc(0.88rem + 0.3vw), 1.1rem);
  --step-0: clamp(1.13rem, calc(1.03rem + 0.45vw), 1.38rem);
  --step-1: clamp(1.35rem, calc(1.22rem + 0.67vw), 1.72rem);
  --step-2: clamp(1.62rem, calc(1.43rem + 0.96vw), 2.15rem);
  --step-3: clamp(1.94rem, calc(1.67rem + 1.35vw), 2.69rem);
  --step-4: clamp(2.33rem, calc(1.96rem + 1.86vw), 3.36rem);
  --step-5: clamp(2.8rem, calc(2.29rem + 2.54vw), 4.2rem);

  /* Utopia Space Scale */
  /* @link https://utopia.fyi/space/calculator?c=320,18,1.2,1200,22,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,&g=s,l,xl,12 */
  --space-3xs: clamp(0.31rem, calc(0.29rem + 0.11vw), 0.38rem);
  --space-2xs: clamp(0.56rem, calc(0.52rem + 0.23vw), 0.69rem);
  --space-xs: clamp(0.88rem, calc(0.81rem + 0.34vw), 1.06rem);
  --space-s: clamp(1.13rem, calc(1.03rem + 0.45vw), 1.38rem);
  --space-m: clamp(1.69rem, calc(1.55rem + 0.68vw), 2.06rem);
  --space-l: clamp(2.25rem, calc(2.07rem + 0.91vw), 2.75rem);
  --space-xl: clamp(3.38rem, calc(3.1rem + 1.36vw), 4.13rem);
  --space-2xl: clamp(4.5rem, calc(4.14rem + 1.82vw), 5.5rem);
  --space-3xl: clamp(6.75rem, calc(6.2rem + 2.73vw), 8.25rem);

  /* One-up pairs */
  --space-3xs-2xs: clamp(0.31rem, calc(0.18rem + 0.68vw), 0.69rem);
  --space-2xs-xs: clamp(0.56rem, calc(0.38rem + 0.91vw), 1.06rem);
  --space-xs-s: clamp(0.88rem, calc(0.69rem + 0.91vw), 1.38rem);
  --space-s-m: clamp(1.13rem, calc(0.78rem + 1.7vw), 2.06rem);
  --space-m-l: clamp(1.69rem, calc(1.3rem + 1.93vw), 2.75rem);
  --space-l-xl: clamp(2.25rem, calc(1.57rem + 3.41vw), 4.13rem);
  --space-xl-2xl: clamp(3.38rem, calc(2.6rem + 3.86vw), 5.5rem);
  --space-2xl-3xl: clamp(4.5rem, calc(3.14rem + 6.82vw), 8.25rem);

  /* Colors */
  --color-dark-values: 14, 22, 39;
  --color-light-values: 250, 250, 250;
  --color-dark: rgb(var(--color-dark-values));
  --color-light: rgb(var(--color-light-values));
  /* TODO accent color(-s?) */
  /* --color-accent: #007ea7; */
  --color-accent: rgb(219, 50, 77);
  /* --color-accent: #ba5a31; */
  --color-body-background: var(--color-light);
  --color-body-text: var(--color-dark);
  --color-focus-outline: var(--color-dark);
  --color-code-line-number: rgba(204, 204, 204, 0.6);

  /* Layout */
  --border-radius: 0.3rem;
  --border-thin: var(--s-5);
  --border-thick: var(--s-2);
  --gutter: var(--space-s-m);
  --max-inline-size: 1200px;
  --measure: 65ch;

  /* Transitions */
  /* TODO needed? */

  /* Typography */
  /* TODO font loading for 'Inter' font */
  --font-main: system-ui, sans-serif;
  --font-size-h1: var(--step-4);
  --font-size-h2: var(--step-3);
  --font-size-h3: var(--step-2);
  --font-size-h4: var(--step-1);
  --font-size-base: var(--step-0);
  --font-weight-header: 700;
  --line-height-base: 1.6;
  --line-height-header: 1.2;

  /* Z-Index */
  --z1: 1;
  --z100: 100;
}

/* TODO dark mode */

@media (prefers-color-scheme: dark) {
  :root {
    --color-light-values: 14, 22, 39;
    --color-dark-values: 250, 250, 250;
  }
}
