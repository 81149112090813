tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.25);
}
tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.5);
}
th {
  background-color: rgba(0, 0, 0, 0.5);
}

td {
  vertical-align: text-top;
}
th {
  vertical-align: bottom;
}

th,
td {
  text-align: left;
}
/* For numeric data, align to right in markdown */

/* Hanging Indents */
th,
td {
  padding: 0.25em 0.5em 0.25em 1em;
  text-indent: -0.5em;
}
