/* 
The center composition horizontally centers all content within the center
element. Using the Flexbox layout module it also supports intrinsic centering,
i.e. elements are centered based on their natural, content-based widths.
*/

.center {
  --center-inline-size: var(--max-inline-size);
  box-sizing: content-box;
  margin-inline: auto;
  max-inline-size: var(--center-inline-size);
  padding-inline: var(--space-s-m, 1rem);
}

.center.intrinsic {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.center.measure {
  --center-inline-size: var(--measure);
}
