blockquote {
  border-inline-start: 3px solid var(--color-accent);
  padding: var(--space-m);
  quotes: '“' '”' '‘' '’';
}

blockquote:before {
  color: var(--color-accent);
  content: '“';
  display: block;
  font-family: 'Noto Serif Display', sans-serif;
  font-size: 5rem;
  font-weight: bold;
  line-height: 3rem;
}
