/* 
The Reel is a robust and efficient antidote to carousel/slider components.
It arranges all elements in a horizontal line without wrapping and handles
overflow automatically by scrolling just the reel element itself.
It is ideal for browsing categories of things: products, photographs etc.
*/

.reel {
  /*
  Shadows as scroll indicators
  Link 1: https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/
  Link 2: https://lea.verou.me/2012/04/background-attachment-local/
  */
  background: linear-gradient(
        to right,
        var(--color-body-background) 30%,
        transparent
      )
      left center,
    linear-gradient(to left, var(--color-body-background) 30%, transparent)
      right center,
    radial-gradient(
        farthest-side at 0 50%,
        rgba(var(--color-dark-values), 0.5),
        rgba(var(--color-dark-values), 0)
      )
      left center,
    radial-gradient(
        farthest-side at 100% 50%,
        rgba(var(--color-dark-values), 0.5),
        rgba(var(--color-dark-values), 0)
      )
      right center;
  background-attachment: local, local, scroll, scroll;
  background-repeat: no-repeat;
  background-size: 30px 100%, 30px 100%, 15px 100%, 15px 100%;
  block-size: auto;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.reel > * {
  flex: 0 0 auto;
}

.reel > img {
  block-size: 100%;
  flex-basis: auto;
  width: auto;
}

.reel > * + * {
  margin-inline-start: 1rem;
}

/* 
The `overflowing` class is added via JS as soon as the content is wider than
the reel element and therefore causes overflow.
It is handled with progressive enhancement via JS to avoid the redundant
padding in case the overflow is not triggered.
In case JS is disabled, add the padding all the time (better to have too much
padding than none at all and potentially obscure content).
*/
.no-js .reel,
.reel.overflowing {
  padding-block-end: 1rem;
}
