/* 
Prose [ˈprōz]: 
  1. the ordinary language people use in speaking or writing
  2. a dull or ordinary style, quality, or condition

Seems accurate enough.
*/

.prose {
  --max-inline-size: var(--measure);
}

.prose.stack > * + * {
  --stack-space: var(--space-l-xl);
}

.prose.stack h1 + time {
  --stack-space: 0;
}

.prose.stack h2 + *,
.prose.stack h3 + *,
.prose.stack h4 + * {
  --stack-space: var(--space-xs-s);
}

.prose .breadcrumbs + h1 {
  margin-block-start: 0;
}
