/* 
A stack is a composition in which spacing along the flow direction 
(vertical in horizontal-tb writing mode) is inserted between elements.

In case the stack component is supposed to be interpreted as a list for 
screen reader software, add `role="list"` to the element the `stack` class
is applied to.
*/

.stack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.stack > * {
  margin-block: 0;
}

.stack > * + * {
  --stack-space: var(--space-m-l, 1em);
  margin-block-start: var(--stack-space);
}

/* 
If the stack is the only child of its parent, nothing will force it to
stretch to the parent's full height. If a split (see below) is used, this 
ensures the split can occur.
*/
.stack:only-child {
  block-size: 100%;
}

/* Split the stack after nth-child */
.stack[data-split-after='1'] > :first-child {
  margin-block-end: auto;
}

.stack[data-split-after='2'] > :nth-child(2) {
  margin-block-end: auto;
}

.stack[data-split-after='3'] > :nth-child(3) {
  margin-block-end: auto;
}

/*
Pre-defined spacing modifiers for ease of use, primarily 
for deliberate nesting of stacks.
Also, reduce the margin between content and directly preceeding headings.
*/
.stack[data-stack-type='dense'] > * + * {
  --stack-space: var(--space-xs-s);
}

.stack[data-stack-type='loose'] > * + * {
  --stack-space: var(--space-l-xl);
}
