/* 
The Switcher element
(based on http://www.heydonworks.com/article/the-flexbox-holy-albatross) 
switches a Flexbox context between a horizontal and a vertical layout at a 
given, container-based breakpoint.
The breakpoint at which the layout switches can be configured by setting the
`--threshold` variable (default: 30rem).
Additionally, the layout is switched to the vertical one if there are more than
5 child elements present.
*/

.switcher {
  --gap: var(--space-xs-s, 1rem);
  --threshold: 30rem;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
}

/* 
Depending on the parsed value of 100%, this will return either a positive
or negative value: positive if the container is narrower than 30rem,
or negative if it is wider. This number is then multiplied by 999 to produce
either a very large positive number or a very large negative number.
A negative flex-basis value is invalid, and dropped, so just the flex-basis 
line is ignored, and the rest of the CSS is still applied. The erroneous
negative flex-basis value is corrected to 0 and—because flex-grow is present — 
each element grows to take up an equal proportion of horizontal space.
*/
.switcher > * {
  flex-basis: calc((var(--threshold) - 100%) * 999);
  flex-grow: 1;
}

/* 
Apply a flex-basis of 100% to each element, only where there are five or more
elements in total. The nth-last-child(n+5) selector targets any elements that
are more than 4 places away from the end of the set. Then the general sibling
combinator (~) applies the same rule to the rest of the elements (it matches
anything after :nth-last-child(n+5)). If there are fewer than 5 items, no
:nth-last-child(n+5) elements are present and the style is not applied.
*/
.switcher > :nth-last-child(n + 5),
.switcher > :nth-last-child(n + 5) ~ * {
  flex-basis: 100%;
}
