/* 
A box layout can be used to visually group elements together, e.g. for a 
modal with text and inputs. 
The box is only concerned with itself and its contents. Properties like margin
etc. reach outside of the box and should be handled elsewhere.
*/

.box {
  --color-box-text: var(--color-body-text);
  --color-box-background: var(--color-body-background);
  background-color: var(--color-box-background);
  border: var(--border-thin) solid;
  color: var(--color-box-text);
  padding: var(--s1, 1.5rem);
}

.box * {
  /* 
  Making every element within the box inherit the color value of the box 
  itself makes it possible to change both background-color and color on 
  the box without needing to overwrite specific elements within the box.
  */
  color: inherit;
}

.box.invert {
  background-color: var(--color-box-text);
  color: var(--color-box-background);
}

/* 
In the absence of a border, a background-color is insufficient for describing 
a box shape because high contrast themes tend to eliminate backgrounds.
By employing a transparent outline the box shape can be restored.

When a high contrast theme is not running, the outline is invisible. The 
outline property also has no impact on layout (it grows out from the element 
to cover other elements if present). When the high contrast theme is switched
on (e.g. Windows High Contrast Mode), it gives the outline a color and the
box is drawn.

The negative outline-offset moves the outline inside the Box's perimeter so
it behaves like a border and no longer increases the box's overall size.
*/
.box.borderless {
  border: 0;
  outline: 0.125rem solid transparent;
  outline-offset: -0.125rem;
}
