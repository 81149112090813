/* 
The cover layout centers one element vertically (the principal element) and 
optionally facilitates one element to be pushed towards the top and bottom each.
*/

.cover {
  --cover-block-size: 100vh;
  display: flex;
  flex-direction: column;
  min-block-size: var(--cover-block-size);
  padding: var(--space-s-m, 1rem);
}

.cover > * {
  margin-block: var(--space-s-m, 1rem);
}

.cover > :first-child:not(h1) {
  margin-block-start: 0;
}

.cover > :last-child:not(h1) {
  margin-block-end: 0;
}

.cover > h1 {
  margin-block: auto;
}
