.font-bigger {
  font-size: 1.2em !important;
}

.font-smaller {
  font-size: 0.9em !important;
}

.line-height-2 {
  line-height: 2 !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-start {
  text-align: start !important;
}

.text-align-end {
  text-align: end !important;
}
