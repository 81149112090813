a {
  color: currentColor;
  text-decoration-color: var(--color-body-text);
  text-decoration-thickness: 0.1em;
  text-underline-offset: 0.3em;
  transition: text-decoration-thickness 0.1s ease-out;
}

a:hover {
  text-decoration-thickness: 0.3em;
}

a[data-state='active'] {
  text-decoration-color: var(--color-accent);
}
