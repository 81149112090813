/* 
The grid layout provides a grid structure but without a hard-coded size for
the grid elements. With the min function the grid automatically switches
from a one-column layout to a flexible grid layout at the specified width
in the calc function.
*/

.grid {
  --gutter: var(--space-s-m, 1rem);
  display: grid;
  grid-gap: var(--gutter);
  --max-columns: 4;
  /* 
  Add 1 to the maximum number of columns to account for the grid gap 
  in a very approximate and broad way.
  TODO could be refined to be slightly more accurate approximation
  */
  --minimum: calc(var(--max-inline-size) / (var(--max-columns) + 1));
}

/* Use the min() function for multiple columns if the browser supports it */
@supports (width: min(250px, 100%)) {
  .grid {
    grid-template-columns: repeat(
      auto-fit,
      minmax(min(var(--minimum), 100%), 1fr)
    );
  }
}
