:focus-visible {
  outline: 3px dotted var(--color-focus-outline);
  outline-offset: 0.3em;
}

/*
Remove the focus from the #main-content element if it was 
focused via the skip link.
*/
#main-content:focus-visible {
  outline: none;
}

.skip-link {
  position: absolute;
  z-index: var(--z100);
}

.skip-link:not(:focus),
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
