/* 
The imposter element allows centrally positioning of an element over the 
viewport, the document or a selected "positioning container" element, e.g. 
to purposefully obscure content (for example content that is locked behind
a paywall).
*/

.imposter {
  --positioning: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  position: var(--positioning);
  transform: translate(-50%, -50%);
}

.imposter.contain {
  --margin: var(--space-s-m, 1rem);
  max-block-size: calc(100% - (var(--margin) * 2));
  max-inline-size: calc(100% - (var(--margin) * 2));
  overflow: auto;
}
