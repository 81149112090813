body {
  background-color: var(--color-body-background, #fafafa);
  color: var(--color-body-text, #161616);
  font-family: var(--font-main, sans-serif);
  font-size: var(--font-size-base, 1rem);
  line-height: var(--line-height-base, 1.6);
  word-wrap: break-word;
}

/* @supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
} */

/* 
Apply measure to all typical inline elements.
*/
blockquote,
dl,
figcaption,
li,
p,
.prose {
  max-inline-size: var(--measure, 65ch);
}

/* 
Global heading font settings
*/
h1,
h2,
h3,
h4 {
  font-weight: var(--font-weight-header, 700);
  hyphens: auto;
  line-height: var(--line-height-header, 1.2);
}

h1 a,
h2 a,
h3 a,
h4 a {
  display: inline-block;
}

.h1,
h1 {
  font-size: var(--font-size-h1);
}

.h2,
h2 {
  font-size: var(--font-size-h2);
}

.h3,
h3 {
  font-size: var(--font-size-h3);
}

.h4,
h4 {
  font-size: var(--font-size-h4);
}

/* Shift the scroll margin of jump link targets up a bit */
[id] {
  /* TODO fallback: scroll-margin-block-start: 2rem;? */
  scroll-margin-block-start: 3ex;
}

/* Color text selection in the site's colors */
/* ::selection {
  background-color: var(--color-body-text);
  color: var(--color-body-background);
} */
