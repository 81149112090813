/*
The cluster composition lays out child elements in a flexible grid-like 
structure while still allowing each child element its own individual width.
*/
.cluster {
  --cluster-gap: var(--space-xs-s, 1rem);
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--cluster-gap);
}
