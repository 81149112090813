/* 
Remove non-essential elements, navigational elements, reduce spacing and
remove all inline-size restrictions to fit more content on a page.
Also remove the dark background for code elements to save on ink (while
still keeping basic syntax highlighting).
Remove the scroll overflow as that would not be visible on the printed page.
*/
@media print {
  body {
    --color-body-background: #fff;
  }

  #site-navigation,
  .skip-link,
  .breadcrumbs,
  #main-content + footer,
  pre .clipboard-button,
  .pagination,
  #tags-desc,
  #tags-desc + [aria-describedby='tags-desc'],
  [aria-labelledby='recommended-posts'] {
    display: none !important;
  }

  #main-content {
    padding-block-start: 0 !important;
  }

  #main-content article.stack > * + * {
    --stack-space: var(--space-xs-s);
  }

  /* 
  Show the URL after the link to show where it links to on the 
  printed page (can't click a link on paper).
  */
  a[href]::after {
    content: ' (' attr(href) ')';
  }

  .prose,
  .wrapper {
    --max-inline-size: 100%;
  }

  .full-bleed,
  .prose picture,
  .prose pre[class*='language-'] {
    max-inline-size: 100%;
  }

  code[class*='language-'],
  pre[class*='language-'] {
    white-space: break-spaces;
  }

  :not(pre) > code[class*='language-'],
  pre[class*='language-'] {
    background: none;
    color: black;
  }

  :not(pre) > code[class*='language-'] *,
  pre[class*='language-'] * {
    color: inherit;
  }
}
