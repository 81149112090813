/* 
The sidebar layout does what it says on the tin: it arranges two elements in
a side-by-side fashion, one being the (narrow) sidebar and one the (wide) main
content.
The order by default is sidebar - non-sidebar, but can be reversed by adding 
the `reverse` class to the `with-sidebar` element.
The width of the sidebar is dictated by the sidebar's content and the minimum
width of the main content (`--min-content-inline-size`), but can be set to a
fixed value by adding the `fixed` class and optionally setting 
`--sidebar-inline-size` to the desired width (default is 20rem).
*/

.with-sidebar {
  --content-min-inline-size: 55%;
  --sidebar-inline-size: 20rem;
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-m-l, 1rem);
}

.with-sidebar > :first-child {
  flex-grow: 1;
}

.with-sidebar > :last-child,
.with-sidebar.reverse > :first-child {
  flex-basis: 0;
  flex-grow: 999;
  min-inline-size: var(--content-min-inline-size);
}

.with-sidebar.reverse > :last-child {
  flex-basis: auto;
  flex-grow: 1;
  min-inline-size: auto;
}

.with-sidebar.fixed > :first-child {
  flex-basis: var(--sidebar-inline-size);
}

.with-sidebar.fixed.reverse > :last-child {
  flex-basis: var(--sidebar-inline-size);
}
